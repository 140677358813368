import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Experiences from './experience/experiences';
import Educations from './formation/educations';

import Competences from './competence/competences';

class Resume extends Component {
  constructor(props) {
    super(props)
  }


  render() {
    return (
      <div>
        <br />
        <Grid className="cv-grid">
          <Cell col={3} style={{ textAlign: 'left' }}>
            <Card shadow={5} style={{ minWidth: '450', margin: 'auto', }}>
              <Paper style={{ paddingLeft: '1em', flex: 'flex', textAlign: 'left' }}>
                <div className="resume-content">
                  <img
                    src="/images/pp.png"
                    alt="avatar"
                    className="avatar-img"
                    style={{ height: '200px' }}
                  />
                </div>
                <h3 style={{ paddingTop: '2em' }}>Ibrahima SOUMARE</h3>
                <h4 style={{ color: 'grey' }}>Cloud Data Data Engineer</h4>
                <hr style={{ borderTop: '3px solid #833fb2', width: '90%' }} />
                <p>
                  Je suis passionné par tout ce qui touche à l'informatique.
                </p>
                <hr style={{ borderTop: '3px solid #833fb2', width: '90%' }} />
                <h5>adresse</h5>
                <p>Issy-les-Moulineaux, 92130</p>
                {/*<h5>Téléphone</h5>
                <p>+33651341927</p>
                */}
                <h5>Email</h5>
                <p>soumareiibrahima@gmail.com</p>
                <h5>Nom de domaine </h5>
                <a href="https://Ibrahima-soumare.com">Ibrahima-soumare.com</a>
                <br />
                <a
                  style={{ color: 'black', fontSize: '50px' }}
                  href="https://www.linkedin.com/in/ibrahima-soumare-848ab5151/"
                  target="_blank">
                  <i className="fa fa-linkedin-square" aria-hidden="true" />
                </a>
                <hr style={{ borderTop: '3px solid #833fb2', width: '90%' }} />
              </Paper>
            </Card>
          </Cell>
          <Cell className="resume-right-col" col={9}>
            <div>
              <Experiences /> 
            </div>
            <hr style={{ borderTop: '6px solid #e22947' }} />
            <div>
              <Educations />
            </div>
            <hr style={{ borderTop: '6px solid #e22947' }} />
            <div>
              <Competences/>
            </div>
          </Cell>
        </Grid>
      </div>
    )
  }
}

export default Resume;
