import React from 'react'
import { Footer, FooterSection, FooterDropDownSection, FooterLinkList, } from 'react-mdl';
import { Link } from '@material-ui/core';



export default class Foot extends React.Component {

    render() {
        return (
            <Footer size="mega" className="footer-color text-align">
                <FooterSection type="middle">
                    <FooterDropDownSection title="">
                    </FooterDropDownSection>
                    <FooterDropDownSection title="Portfolio">
                        <FooterLinkList>
                            <Link href="/">Accueil</Link>
                            <br />
                            <Link href="/cv">CV</Link>
                        </FooterLinkList>
                    </FooterDropDownSection>
                    <FooterDropDownSection title="Details">
                        <FooterLinkList>
                            <Link href="/projets">Projets</Link>
                        </FooterLinkList>
                    </FooterDropDownSection>
                    <FooterDropDownSection title="Contact">
                        <FooterLinkList>
                            <Link href="/apropos">A propos</Link>
                            <br />
                            <Link href="/contactes">Contacter</Link>
                        </FooterLinkList>
                    </FooterDropDownSection>
                </FooterSection>
            </Footer>
        )
    }
}