import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class Education extends Component {

  getFields() {
    let id = this.props.formation.startYear;

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id={"panel2bh-header" + id}>
          <Typography className="font-weight-bold"> Les matières : </Typography>
        </ExpansionPanelSummary>
        {this.getFieldsDescriptions()}
      </ExpansionPanel>
    )
  }

  getFieldsDescriptions() {
    const fields = this.props.formation.fields;
    if (fields !== undefined) {
      return fields.map(field => {
        return (
          <ExpansionPanelDetails
          key={field.name}>
            <Typography>
              {field.name}  {field.description}
            </Typography>
          </ExpansionPanelDetails>
        )
      })
    }
  }

  render() {
    return (
      <Grid>
        <Cell col={3}>
          <p>{this.props.formation.startYear} - {this.props.formation.endYear}</p>
        </Cell>
        <Cell col={9}>
          <h6 style={{ marginTop: '0px' }}>{this.props.formation.schoolName}</h6>
          <code style={{color: "white"}}>Option : {this.props.formation.schoolOption}</code>
          <br/>
          <br/>
          <p>{this.props.formation.schoolDescription}</p>
          {this.getFields()}
        </Cell>
      </Grid>
    )
  }
}

export default Education;
