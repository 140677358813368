import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { CardTitle, Button } from 'react-mdl';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const styles = {
  style: {
    marginTop: '20px',
    marginLeft: '25px',
    marginRight: '25px',
    paddingTop: '25px',
    paddingLeft: '25px',
    paddingRight: '20px',
    paddingBottom: '20px',
    minWidth: '450'
  },
  dialog: {
    width: 12
  }
};


class Hobbie extends Component {

  constructor(props) {
    super(props)
    this.state = {
      statusDialog: false
    };
  }

  render() {
    const url = 'url(' + this.props.hobbie.images[1] + ') center / cover'
    return (
      <Card shadow={5} style={styles.style}>
        <CardTitle style={{ color: 'black', background: url }} >
        </CardTitle>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {this.props.hobbie.title}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={() => this.openDialog()}
            color="primary">
            Détail
                </Button>
        </CardActions>
        {this.description()}
      </Card>
    )
  }

  closeDialog() {
    this.setState({ statusDialog: false })
  }

  openDialog() {
    this.setState({ statusDialog: true })
  }


  description() {
    const { statusDialog } = this.state;
    if (!statusDialog) {
      return (
        <div></div>
      )
    }

    return (
      <React.Fragment>
        <Dialog
          open={statusDialog}
          //onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">Pourquoi J'aime {this.props.hobbie.title}</DialogTitle>
          <DialogContent>
            <Card className="row container">
              <div className="col-xs-12 col-sm-6 col-lg-5">
                <img
                  src={this.props.hobbie.images[0]}
                  style={{ width: '50%', marginBottom: '20px', marginTop: '20px' }} />
              </div>
              <div className="col-xs-12 col-sm-6 col-lg-7">
                {this.getDescriptionsByLine()}
              </div>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.closeDialog()}
              color="primary">
              Fermer
          </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }

  getDescriptionsByLine() {
    const { description } = this.props.hobbie;
    if (this.props.hobbie.description !== undefined) {

      return description.map(descript => {
        return (
          <Typography
            key={descript}>
            <DialogContentText>
              {descript}
            </DialogContentText>
          </Typography>
        )
      })
    }
  }
}

export default Hobbie;
