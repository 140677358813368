import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hobbie from './hobbie'


const styles = {
  style : {
    marginTop: '20px',
    marginLeft: '20px',
    marginRight: '20px',
    minWidth: '20px',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
    minWidth: '450',
    
  },
  appBar : {
    marginLeft: '20px',
    marginRight: '20px',
    minWidth: '20px',
    paddingTop: '20px',
    textAlign: 'center'
  },
  container: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center'
  }
};

class Hobbies extends Component {

    constructor(props){
        super(props);
        
    }

    hobbies(){
      if(this.props.hobbies !== undefined ) {
        const {hobbies} =this.props.hobbies; 
        return hobbies.map(hobbie => {
          return(
            <Hobbie hobbie={hobbie} key={hobbie.title}/>
          )
        })
      }
    }

  render() {
    return(
        <div className="col-12 row container border-top-2" 
        style={{paddingTop: '20px'}}>
        <AppBar 
        position="static"
        style={{background:'#536976', margin:'auto'}}>
        <Toolbar >
          <Typography  variant="h6" style={styles.appBar}>
            Centres d’Intérêt
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="row container col-12" style={styles.container}>
        {this.hobbies()}
      </div>
      </div>
    )
  }
}




export default Hobbies;
