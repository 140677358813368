import React, { Component } from 'react';
import { Tabs, Tab, Grid, Cell, CardTitle, CardText, Button } from 'react-mdl';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';



const styles = {
  card: {
    marginTop: '20px',
    marginRight: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '450'
  },
  container: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center'
  }
}


class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: 1 };
  }



  toggleCategories() {
    if (this.state.activeTab === 0) {
      return (
        <div className="row container" style={styles.container}>
          <Card style={styles.card}>
            <CardTitle style={{ color: 'black', height: '176px', background: 'url(/images/kmean.png) center / cover' }} >
            </CardTitle>
            <CardText style={{ color: 'black' }}>
              Algorithme Kmean en big data implémenté en Python et Scala
            </CardText>
            <CardActions >
              <Button colored>
                <a target="_blank" href="https://github.com/isoume/kmeans">GitHub</a>
              </Button>
            </CardActions>
          </Card>

          <Card style={styles.card}>
            <CardTitle style={{ color: 'black', height: '176px', background: 'url(/images/pyspark_excel.png) center / cover' }} >
            </CardTitle>
            <CardText style={{ color: 'black' }}>
              Spark, lire un fichier excel en dataframe et le transformé en rdd
            </CardText>
            <CardActions >
              <Button colored>
                <a target="_blank" href="https://github.com/isoume/read_excel_pyspark.git">GitHub</a>
              </Button>
            </CardActions>
          </Card>

          <Card style={styles.card}>
            <CardTitle style={{ color: 'black', height: '176px', background: 'url(/images/neuronal_network.jpeg) center / cover' }} >
            </CardTitle>
            <CardText style={{ color: 'black' }}>
              Réseau de neurone: La méthode convolutive utilisée pour classifier des images
            </CardText>
            <CardActions >
              <Button colored>
                <a target="_blank" href="https://github.com/isoume/ConvolutifModel/blob/master/ConvolutifModel.ipynb">GitHub</a>
              </Button>
            </CardActions>
          </Card>
        </div>
      )
    }
    else if (this.state.activeTab === 1) {
      return (
        <div className="container row center" style={styles.container}>
          <Card style={styles.card}>
            <CardTitle style={{ color: 'black', height: '176px', background: 'url(/images/ansible.jpg) center / cover' }} >
            </CardTitle>

            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                Créer une instance d'AWS en utilisant Ansible
              </Typography>
            </CardContent>

            <CardActions >
              <Button colored>
                <a target="_blank" href="https://github.com/isoume/create-instance-ec2-with-ansible">GitHub</a>
              </Button>
            </CardActions>
          </Card>

          <Card style={styles.card}>
            <CardTitle style={{ color: 'black', height: '176px', background: 'url(/images/terraform.png) center / cover' }} >
            </CardTitle>

            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                Création de ressource de manière automatisée avec Terraform
              </Typography>
            </CardContent>

            <CardActions >
              <Button colored>
                <a target="_blank" href="https://github.com/isoume/terraform.git">GitHub</a>
              </Button>
            </CardActions>
          </Card>

          <Card style={styles.card}>
            <CardTitle style={{ color: 'black', height: '176px', background: 'url(/images/jenkins.png) center / cover' }} >
            </CardTitle>
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                Installation de Jenkins dans une machine virtuelle
              </Typography>
            </CardContent>
            <CardActions >
              <Button colored>
                <a target="_blank" href="https://github.com/isoume/Jenkins.git">GitHub</a>
              </Button>
            </CardActions>
          </Card>
          <Card style={styles.card}>
            <CardTitle style={{ color: 'black', height: '176px', background: 'url(/images/docker_ml_flaks.png) center / cover' }} >
            </CardTitle>
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                Déployer un modèle de machine Learning dans un container docker avec Flask
              </Typography>
            </CardContent>
            <CardActions >
              <Button colored>
                <a target="_blank" href="https://github.com/isoume/deploy_ml_model">GitHub</a>
              </Button>
            </CardActions>
          </Card>

        </div>
      )
    } else if (this.state.activeTab === 2) {
      return (
        <div className="row" style={styles.container}>
          {/* Project 1 */}
          <Card style={styles.card}>
            <CardTitle style={{ color: 'black', height: '200px', background: 'url(/images/reactjs.png) center / cover' }} >
            </CardTitle>
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                Prototype d'inscription et connexion avec ReactJs
              </Typography>
            </CardContent>
            <CardActions >
              <Button colored>
                <a target="_blank" href="https://github.com/isoume/web.de.donnees">GitHub</a>
              </Button>
            </CardActions>
          </Card>
        </div>


      )
    }
  }



  render() {
    return (
      <div style={{ minHeight: "82.4%" }}>
        <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId })} ripple>
          <Tab className="font-weight-bold">Machine Learning</Tab>
          <Tab className="font-weight-bold">Infrastructure / Cloud</Tab>
          <Tab className="font-weight-bold">React</Tab>
        </Tabs>
        <Grid style={{ paddingTop: '3em' }}>
          <Cell col={12}>
            <div>{this.toggleCategories()}</div>
            <br/>
          </Cell>
        </Grid>

      </div>
    )
  }

}

export default Projects;
