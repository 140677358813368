import React, { Component } from 'react';
import Education from './education';
import Card from '@material-ui/core/Card';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const styles = {
    style:{ 
        paddingLeft: '2px', 
        paddingRight: '2px', 
        paddingBottom: '2px'
    }
}

class Educations extends Component {
    constructor(props) {
        super(props)
        this.formation1 = {
            startYear: 2019,
            endYear: 2020,
            schoolName: "Master 2 Université Paris Dauphine",
            schoolOption: "Informatique décisionnelle",
            schoolDescription: "Le parcours MIAGE Informatique Décisionnelle vise à doter les étudiants de compétences scientifiques pour améliorer le fonctionnement des organisations par l'utilisation des outils de l'informatique décisionnelle et l'application d'une approche scientifique",
            fields: [
                { name: " - Création d'entreprise", description: "" },
                { name: " - Décision en Entreprise", description: "" },
                { name: " - Entrepôts de données", description: "" },
                { name: " - Marketing et stratégie digitale", description: "" },
                // {name: " - Modélisation en Aide à la décision", description: ""},
                // {name: " - Programmation par contraintes et ses applications", description: ""},
                { name: " - Qualité des données", description: "" },
                { name: " - Systèmes, Langages et Paradigmes pour le Big Data", description: "" },
                { name: " - Visualisation de données", description: "" },
                { name: " - Data Mining/Machine learning", description: "" },
                { name: " - Projet en Business Intelligence - Big Data", description: "" },
                { name: " - Négociation", description: "" },
                { name: "  ... ", description: "" }
            ]
        }

        this.formation2 = {
            startYear: 2018,
            endYear: 2019,
            schoolName: "Master 1 Université Paris Dauphine",
            schoolOption: "MIAGE en apprentissage",
            schoolDescription: "MIAGE (Méthode Informatique, Appliquée pour la Gestion des Entreprises) vise à apporter une compréhension approfondie du fonctionnement des organisations, de leurs structures et de leurs impératifs stratégiques, des outils formels de management scientifique et des nouvelles technologies de l’information et de la communication",
            fields: [
                { name: " - Programmation Objet avancée", description: "" },
                { name: " - Intelligence artificielle", description: "" },
                { name: " - Introduction au Machine Learning", description: "" },
                { name: " - Systèmes de gestion de bases de données", description: "" },
                { name: " - Systèmes et algorithmiques répartis", description: "" },
                { name: " - Marketing", description: "" },
                { name: " ...", description: "" }
            ]
        }

        this.formation3 = {
            startYear: 2017,
            endYear: 2018,
            schoolName: "Licence 3 Université Paris Nord",
            schoolOption: "Informatique",
            schoolDescription: "Le diplôme de licence a plusieurs objectifs : faire acquérir à l’étudiant des savoirs et des compétences scientifiques, techniques et pratiques et développer chez celui-ci des aptitudes (transversales et préprofessionnelles) qui lui permettront d’évoluer dans son domaine d’activité.",
            fields: [
                { name: " - Automate", description: "" },
                { name: " - Modélisation UML", description: "" },
                { name: " - Probabilité Statistique", description: "" },
                { name: " ...", description: "" }
            ]
        }



    }
    render() {
        return (
            <div>
                <ExpansionPanel
                    style={styles.style}
                    defaultExpanded={true}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2bh-header">
                        <Typography className="serif font-weight-bold"> Education</Typography>
                    </ExpansionPanelSummary>

                    <Card style={{ background: '#2c5364', color: 'white' }}>
                        <Education
                            formation={this.formation1}
                        />
                    </Card>
                    <br />
                    <Card style={{ background: '#2c5364', color: 'white' }}>
                        <Education
                            formation={this.formation2}
                        />
                    </Card>
                    <br />
                    <Card style={{ background: '#2c5364', color: 'white' }}>
                        <Education
                            formation={this.formation3}
                        />
                    </Card>
                </ExpansionPanel>

            </div>
        )
    }
}

export default Educations;
