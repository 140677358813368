import React, { Component } from 'react';
import Skills from './skills';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const styles = {
    style:{ 
        paddingLeft: '2px', 
        paddingRight: '2px', 
        paddingBottom: '2px'
    }
}


class Competences extends Component {
    constructor(props) {
        super(props)
        this.skills = [{
            domain: "Programmation",
            skills: [
                { skill: "Python", value: 6 },
                { skill: "Java", value: 6 },
                { skill: "Javascript", value: 5 },
                { skill: "C++", value: 5 },
                { skill: "PHP", value: 3 },
            ]
        },
		{
            domain: "Framework",
            skills: [
                { skill: "Apache Airflow", value: 4 },
                { skill: "Apache Spark", value: 4 },
                { skill: "Apache Kafka ", value: 4 },
                { skill: "Terraform ", value: 4 },
                { skill: "Ansible ", value: 2 },
                { skill: "Spring Boot", value: 3 },
                { skill: "VueJs", value: 4 },
                { skill: "ReactJs", value: 4 },
                {
					skill: "Databricks",
					value: 3,
					certifified: [
						{
							description: "Databricks Data Engineer",
							link: "https://credentials.databricks.com/6d6ea392-a5b3-493b-9c7f-e6412549491a"
						}
					]
				}
            ]
        },
		{
            domain: "Logiciels",
            skills: [
                { skill: "Talend Data Integrator", value: 5 },
                { skill: "Qlik Sense", value: 4 },
                { skill: "Power BI", value: 4 },
                { skill: "Intellij", value: 4 },
                { skill: "PyCharm", value: 4 }
            ]
        },
		{
            domain: "Cloud",
            skills: [
                {
					skill: "AWS (Amazon Web Service)",
					value: 5,
					certifified: [
						{
							description: "AWS Solutions Architect",
							link: "https://www.youracclaim.com/badges/4d69fbc9-22c9-44af-a1dd-5f4196e47545?source=linked_in_profile"
						}
					]
				},
                {
                    skill: "GCP (Google Cloud)",
                    value: 4,
					certifified: [
						{
							description: "Cloud Security",
							link: "https://google.accredible.com/d816e2a8-b3fd-4b1e-83d7-a0676ebb1064"
						},
						{
							description: "Data Engineer",
							link: "https://www.credential.net/63cf4c49-a6d3-4cd4-8234-ea80fd3c237b"
						}
					]
                }
            ]
        }
		]
    }
    render() {
		const skills=this.skills.map((skill,index)=>{
            return (
                <div>
                    <hr style={{ borderTop: '2px solid #FFAABB' }} />
                    <Skills
                        skill={skill} />
                    <br />
                    <hr style={{ borderTop: '2px solid #FFAABB' }} />
                </div>
            )
        })
        return (
            <div>
                <ExpansionPanel
                    style={styles.style}
                    defaultExpanded={true}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2bh-header">
                        <Typography className="serif font-weight-bold"> Compétences</Typography>
                    </ExpansionPanelSummary>
					{skills}
                </ExpansionPanel>

            </div>
        )
    }
}

export default Competences;
