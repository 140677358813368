import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Experience from './experience';



const styles = {
    style:{ 
        paddingLeft: '2px', 
        paddingRight: '2px', 
        paddingBottom: '2px'
    }
}


class Experiences extends Component {

    constructor(props) {
        super(props)
        this.experiences = [{
            startYear: 2022,
            startMonth: "sept",
            endYear: "En cours",
            endMonth: "",
            jobName: "Consultant Cloud Data Engineer",
            entrepriseName: "E.Leclerc",
            entrepriseUrl: "https://www.recrutement.leclerc/societes/galec/",
            entreprisePlace: "1 Bd de la Madeleine, 75001 Paris",
            jobDescription: "E.Leclerc est une coopérative de commerçants et une enseigne de grande distribution à prédominance alimentaire d'origine française.",
            missions: [
                { detail: "- Identification et analyse des besoins en architecture de stockage et en traitement de données." },
                { detail: "- La mise en place des pipelines d'acquisition, de traitement de stockage de données" },
                { detail: "- Orchestrer les différentes étapes des pipelines"},
                { detail: "- Déploiement et maintenance des pipelines dans le Cloud. (GCP)" },
                { detail: "- Mise en place de tests unitaires et des bonnes pratiques" },
                { detail: "- Rédaction de documents applicatifs nécessaires au transfert de connaissances de l'entité et en dehors de l'entité." },
            ],
            technologies: [
                { name: "- Google Cloud Platform (GCP)" },
                { name: "- Python" },
                { name: "- JavaScript" },
                { name: "- Git" },
                { name: "- NoSQL" },
                { name: "- MongoDB" },
                { name: "- Docker" },
                { name: "- SQL" },
                { name: "- Beautifulsoup" },
                { name: "- RabbitMQ" }
            ]
        },{
            startYear: 2020,
            startMonth: "nov",
            endYear: 2022,
            endMonth: "sept",
            jobName: "Data Engineer / Data Scientist",
            entrepriseName: "Wüest Partner",
            entrepriseUrl: "https://www.wuestpartner.com/fr-fr/",
            entreprisePlace: "1 Bd de la Madeleine, 75001 Paris",
            jobDescription: "Wüest Partner est une société de services innovante et indépendante dans le secteur immobilier. Depuis 1985, nous créons d’excellentes bases de décision en combinant expertise, données et solutions numériques.",
            missions: [
                { detail: "- Identification et analyse des besoins en architecture de stockage et en traitement de données." },
                { detail: "- Mise en place des pipelines d’acquisition et de distribution des données." },
                { detail: "- Déploiement et maintenance des pipelines dans le Cloud. (GCP)" },
                { detail: "- Mise en place de tests unitaires et des bonnes pratiques" },
                { detail: "- Rédaction de documents applicatifs nécessaires au transfert de connaissances de l'entité et en dehors de l'entité." },
            ],
            technologies: [
                { name: "- Google Cloud Platform (GCP)" },
                { name: "- Python" },
                { name: "- JavaScript" },
                { name: "- Git" },
                { name: "- NoSQL" },
                { name: "- MongoDB" },
                { name: "- Docker" },
                { name: "- SQL" },
                { name: "- Beautifulsoup" },
                { name: "- RabbitMQ" }
            ]
        },
        {
            startYear: 2019,
            startMonth: "sept",
            endYear: 2020,
            endMonth: "sept",
            jobName: "Développeur & Intégrateur de données",
            entrepriseName: "Chez  AMFINE services & software",
            entrepriseUrl: "https://www.amfinesoft.com/",
            entreprisePlace: "68 Rue du Faubourg Saint-Honoré, 75008 Paris",
            jobDescription: "AMFINE est un fournisseur de services de reporting de fonds et réglementaires les plus complets en Europe.",
            missions: [
                { detail: "- Participation à la conception" },
                { detail: "- Spécifications techniques et fonctionnelles" },
                //{detail: "- Développement back end Java"},
                { detail: "- Développement front end JS" },
                { detail: "- Mise en place de tests unitaires et des bonnes pratiques" },
                { detail: "- Force de proposition sur les améliorations à apporter" },
                { detail: "- Automatisation de l'intégration des Net Asset Value reçus auprès de nos clients par mail ou SFTP  dans notre base de données" },
                { detail: "- Mettre en place un outil de transformation des données vers le format d'AMFINESOFT." }
            ],
            technologies: [
                { name: "- JAVA (Spring Boot)" },
                { name: "- Jenkins" },
                { name: "- Cloud AWS (Lambda, API Gateway, S3, CloudFormation, EC2, DynamoDB)" },
                { name: "- Talend Data Integrator" },
                { name: "- Python (Boto3, Jenkinsapi), JAVA, JavaScript (Vuejs)" }
            ]
        },
        {
            startYear: 2018,
            startMonth: "sept",
            endYear: 2019,
            endMonth: "sept",
            jobName: "Apprenti en Business Intelligence",
            entrepriseName: "Au Ministère de l'Enseignement Supérieur de la Recherche et de l'innovation",
            entrepriseUrl: "https://www.enseignementsup-recherche.gouv.fr/",
            entreprisePlace: "Région de Paris 75015",
            jobDescription: "MESRI est le ministère de la France qui supervise l'enseignement supérieur de la recherche et de l'innovation",
            missions: [
                { detail: "- Collecter et agréger des données venant d'API, base de données et Excel en utilisant Talend" },
                { detail: "- Création des tableaux de bord avec VBA, QlikSense" },
                { detail: "- Création d'une application web pour la visualisation de données" }
            ],
            technologies: [
                { name: "- Python (Flask)" },
                { name: "- Reactjs" },
                { name: "- VBA, QlikSense" },
                { name: "- Talend Data Integrator" }
            ]
        },
        {
            startYear: 2018,
            startMonth: "mai",
            endYear: 2018,
            endMonth: "juil",
            jobName: "Développeur Python & Deep Learning",
            entrepriseName: "LIPN Laboratoire Informatique Université Paris Nord",
            entrepriseUrl: "https://www.univ-paris13.fr/laboratoires/",
            entreprisePlace: "Villetaneuse",
            jobDescription: "Comparaison des différentes méthodes d'apprentissage en utilisant les modèles de réseaux de neurones.",
            missions: [
                { detail: " - Réaliser des modèles d'algorithmes de machine learning en utilisant le langage PYTHON." }
            ],
            technologies: [
                { name: " - PANDAS" },
                { name: " - NUMPY" },
                { name: " - TENSORFLOW" },
                { name: " - SCIKIT-LEARN" }
            ]
        }]
    }

    render() {
        const experiences=this.experiences.map((item,index)=>{
            return (
                <div>
                    <Card style={{ background: '#2c5364', color: 'white' }} >
                        <Experience experience={item} />
                    </Card>
                    <br />
                </div>
            )
        })
        return (
            <div>
                <ExpansionPanel
                    defaultExpanded={true}
                    style={styles.style}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2bh-header">
                        <Typography className="serif font-weight-bold"> Expérience</Typography>
                    </ExpansionPanelSummary>
                    {experiences}
                </ExpansionPanel>
            </div>
        )
    }
}

export default Experiences;
