import React, { Component } from 'react';
import { Tabs, Tab, Grid, Cell, CardTitle, CardText, Button } from 'react-mdl';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';



const styles = {
  card: {
    marginTop: '20px',
    marginRight: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '450'
  },
  container: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center'
  }
}


class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: 0 };
  }



  toggleCategories() {
    if (this.state.activeTab === 0) {
      return (
        <div className="row container" style={styles.container}>
          <Card style={styles.card}>
            <CardTitle style={{ color: 'black', height: '176px', background: 'url(/images/airflow.jpeg) center / cover' }} >
            </CardTitle>
            <CardText style={{ color: 'black' }}>
              Installation de Apache Airflow
            </CardText>
            <CardActions >
              <Button colored>
                <a target="_blank" href="https://medium.com/@ibrahima-soumare/d%C3%A9marrer-avec-apache-airflow-fb166bc83a14">Medium</a>
              </Button>
            </CardActions>
          </Card>
        </div>
      )
    }
  }

  render() {
    return (
      <div style={{ minHeight: "82.4%" }}>
        <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId })} ripple>
          <Tab className="font-weight-bold">Tech</Tab>
        </Tabs>
        <Grid style={{ paddingTop: '3em' }}>
          <Cell col={12}>
            <div>{this.toggleCategories()}</div>
            <br/>
          </Cell>
        </Grid>

      </div>
    )
  }

}

export default Articles;
