import React, { Component } from 'react';
import { Grid, Cell, List, ListItem, ListItemContent } from 'react-mdl';
import Paper from '@material-ui/core/Paper';
//import EnvoiCv from './envoiCv';
import Card from '@material-ui/core/Card';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';


const styles = {
  main:{
    minHeight: "82.4%",
    margin: 'auto',
    justifyContent: 'center'
  }
}


class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false
    };
    this.close = this.close.bind(this)
  }

  openDialog() {
    this.setState({openDialog: true});
  }

  getOpenDialog() {
    const {openDialog} = this.state;
    return openDialog;
  }

  close() {
    this.setState({openDialog: false});
  }

  render() {
    return(
      <div className="contact-body" style={styles.main}>
        {/*<EnvoiCv openDialog={this.getOpenDialog()} close={this.close}/>*/}
        <br/>
        <Grid className="contact-grid ">
          <Cell col={6}>
          <Card shadow={5} style={{minWidth: '450', margin: 'auto'}}>
            <Paper style={ {textAlign: 'center',flex: 'flex'}}> 
                  <h3>Ibrahima SOUMARE</h3>
            <br/>
            <img
              src="/images/pp.png"
              alt="avatar"
              style={{height: '250px'}}
               />
              <br/>
              <p style={{ width: '75%', margin: 'auto', paddingTop: '2.5em'}}>
                N'hésitez pas à me contacter. Je suis disponible et à l'écoute !
              </p>
              <br/><br/>
            </Paper>
            <br/>
          </Card>
          </Cell>
          <Cell col={6}>
          <Card shadow={5} style={{minWidth: '450', margin: 'auto'}}>
            <Paper style={ {textAlign: 'center',flex: 'flex'}}> 
                  <h2>Contactez-moi</h2>
                  <br/><br/>
                  <hr/>
                  <br/>
            <div className="contact-list">
              <br/>
              <List>
                <ListItem>
                  <ListItemContent style={{fontSize: '15px', fontFamily: 'Anton'}}>
                    <EmailIcon color="secondary" style={{ fontSize: 45 }}/>
                    soumareiibrahima@gmail.com
                  </ListItemContent>
                </ListItem>
                <br/>
                <ListItem>
                  <ListItemContent style={{fontSize: '15px', fontFamily: 'Anton', color: 'black'}}>
                    {/* LinkedIn */}
                    <a 
                      style={{color: 'black'}}
                      href="https://www.linkedin.com/in/ibrahima-soumare-848ab5151/"  
                      target="_blank">
                        <LinkedInIcon color="primary" style={{ fontSize: 45 }}/>
                         LinkedIn
                    </a>
                  </ListItemContent>
                  <ListItemContent >
                    <br/>
                    {/*<Button
                      autoFocus
                      margin="dense"
                      onClick={() => this.openDialog()} 
                      color="primary">
                        Demander son CV
                    </Button>*/}
                    </ListItemContent>
                </ListItem>
                <br/><br/>
              </List>
            </div>
            <br/>
            </Paper>
            <br/>
          </Card>
          </Cell>
        </Grid>
        <br/>
      </div>
    )
  }
}

export default Contact;
