import React, { Component } from 'react';
import { Grid, Cell, Card } from 'react-mdl';
import Tooltip from '@material-ui/core/Tooltip';
import { CardMedia } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';


const styles = {
  style: { margin: '20px' },
  card: {
    marginTop: '20px',
    marginRight: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '450'
  },
  container: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center'
  },
  main:{
    minHeight: "82.4%"
  },
  paper:{
    fontSize: "25px"
  }
}


class Landing extends Component {

  render() {
    return (
      <div style={styles.main} className="row align-items-center">
        <Grid className="landing-grid">
          <Cell col={12}>
            <Card className="col-12">
              <CardMedia>
                <img
                  src="/images/pp.png"
                  alt="avatar"
                  className="avatar-img"
                  style={{ borderRadius: '5% 5% 5% 5%' }}
                />
              </CardMedia>
              <Paper className="container row text-align" style={styles.container}>
                  <div style={styles.style}>
                    <p style={styles.paper}>Data Engineer </p>
                  </div>
              </Paper>
              <Card className="text-align col-12">
                <div className="container row text-align" style={styles.container}>
                  <div style={styles.style}><code>Cloud </code></div>
                  <div style={styles.style}><code>Data Engineer </code></div>
                  <div style={styles.style}><code>DevOps </code></div>
                  <div style={styles.style}><code>BI </code></div>
                </div>
                <hr />
                {this.getBr()}
                <div >
                  {/* LinkedIn */}
                  <Tooltip title="Linkedin">
                    <a href="https://www.linkedin.com/in/ibrahima-soumare-848ab5151/" rel="noopener noreferrer" target="_blank">
                      <IconButton aria-label="play/pause">
                        <LinkedInIcon color="primary" style={{ fontSize: 40 }} />
                      </IconButton>
                    </a>
                  </Tooltip>
                  {/* Kaggle 
              <Tooltip title="Kaggle">
                <a href="https://github.com/isoume" rel="noopener noreferrer" target="_blank">
                  <i className="fa fa-github-square" aria-hidden="true" />
                </a>
                </Tooltip>
              */}

                  {/* Github */}
                  <Tooltip title="Github" >
                    <a href="https://github.com/isoume" rel="noopener noreferrer" target="_blank">
                      <IconButton aria-label="play/pause">
                        <GitHubIcon style={{ fontSize: 40 }} />
                      </IconButton>
                    </a>

                  </Tooltip>
                  {/* Youtube */}
                  <Tooltip title="Youtube">
                    <a
                      href="https://www.youtube.com/channel/UC9HUN-7lWmOyWxrrVIQ0jSg?view_as=subscriber"
                      rel="noopener noreferrer"
                      target="_blank">
                      <IconButton aria-label="play/pause">
                        <YouTubeIcon color="secondary" style={{ fontSize: 40 }} />
                      </IconButton>
                    </a>
                  </Tooltip>
                  {/* Medium */}
                  {/*
                  <Tooltip title="Medium">
                    <a
                      href="https://medium.com/@ibrahima-soumare/d%C3%A9marrer-avec-apache-airflow-fb166bc83a14"
                      rel="noopener noreferrer"
                      target="_blank">
                      <IconButton aria-label="play/pause">
                        <GitHubIcon color="secondary" style={{ fontSize: 40 }} />
                      </IconButton>
                    </a>
                  </Tooltip>
                */}
                </div>
                {this.getBr()}
                {this.getBr()}
              </Card>
            </Card>
          </Cell>
        </Grid>
      </div>


    )
  }

  getBr() {
    return (
      <div>
        <br />
      </div>
    )
  }
}

export default Landing;
