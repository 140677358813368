import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import LandingPage from './accueil';
import AboutMe from './apropos';
import Contact from './contact';
import Projects from './projects';
import Articles from './articles';
import Resume from './cv';

const Main = () => (
  <Switch>
    <Route exact path="/" component={LandingPage} />
    <Route path="/apropos" component={AboutMe} />
    <Route path="/contactes" component={Contact} />
    <Route path="/projets" component={Projects} />
    <Route path="/articles" component={Articles} />
    <Route path="/cv" component={Resume} />
    <Route path='*' render={() => <Redirect to='/' />} />
  </Switch>
)

export default Main;
