import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';




class Experience extends Component {
  
  getMissions(){
    return(
      <ExpansionPanel 
      defaultExpanded={false}>
        <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2bh-header">
        <Typography className= "serif font-weight-bold"> Missions réalisées :</Typography>
        </ExpansionPanelSummary>
        {this.getDetailsMissions()}
    </ExpansionPanel>
    )
  }

  getDetailsMissions(){
    const missions = this.props.experience.missions;
    let i = 0;
    if(missions !== undefined){
        return missions.map(mission =>{
          i++;
          return(
            <ExpansionPanelDetails
              key={mission.detail + i}>
              <Typography>
                  {mission.detail}
              </Typography>
          </ExpansionPanelDetails>
          )
        })
    }
  }


  getTechnologies(){
    
    return(
      <ExpansionPanel>
        <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
        <Typography className= "font-weight-bold"> Technologies utilisées : </Typography>
        </ExpansionPanelSummary>
        {this.getTechnologiesDetails()}
    </ExpansionPanel>
    )
  }

  getTechnologiesDetails(){
    const technologies = this.props.experience.technologies;
    if(technologies !== undefined){
      let i = 0;
        return technologies.map(technologie =>{
          i++;
          return(
            <ExpansionPanelDetails
              key={technologie.name + i}>
              <Typography className="">
                  {technologie.name}
              </Typography>
          </ExpansionPanelDetails>
          )
        })
    }

    return(
      <ExpansionPanelDetails>
          <Typography>
              Détails des technologies :
          </Typography>
      </ExpansionPanelDetails>
    )
  }


  render() {
    return(
      <Grid>
        <Cell col={3}>
          <p>{this.getBeginDate()} - {this.getEndDate()}</p>
        </Cell>
        <Cell col={9}>
          <h5 style={{marginTop:'0px'}}>{this.props.experience.jobName}</h5>
          
          <a className=" text-white" href={this.props.experience.entrepriseUrl} target="_blank">
            <code>
               {this.props.experience.entrepriseName}
            </code>
          </a>
          <br/><br/>
          {/*contexte de la mission et équipe*/ }
          <p>{this.props.experience.jobDescription}</p>
          {/*les missions réalisées*/ }
          {this.getMissions()}
          {/*téchologies utilisées */ }
          {this.getTechnologies()}
        </Cell>
      </Grid>
    )
  }

  getBeginDate(){
    const month = this.props.experience.startMonth;
    const year =this.props.experience.startYear;
    if(typeof(year) === 'string'){
      return year;
    }

    return month.concat('/', year)
  }

  getEndDate(){
    const month = this.props.experience.endMonth;
    const year =this.props.experience.endYear;
    if(typeof(year) === 'string'){
      return year;
    }

    return month.concat('/', year)
  }
}

export default Experience;
