import React, { Component } from 'react';
import { Grid, Cell, Card, CardTitle } from 'react-mdl';
import Paper from '@material-ui/core/Paper';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';





class Skills extends Component {
  open = false;
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this.handleClose = this.handleClose.bind(this);
    this.getDialogFrame = this.getDialogFrame.bind(this);

  }

  getCertifications(skill){
    if(skill.certifified != undefined){
      
      return skill.certifified.map((certif, index) => {
        return (
          <div>
            <Button colored>
              <a target="_blank" href={certif.link}>{certif.description}</a>
            </Button>
              <br />
          </div>
      )
      })
    }
    return null
  }

  getSkills() {
    const skills = this.props.skill.skills;
    if (skills !== undefined) {
      return skills.map((skill, i) => {
        return (
          <Cell col={4} key={skill.skill + i}>
            <Paper variant="outlined" md={4} style={{ textAlign: 'center' }} >
              <h5>{skill.skill}</h5>
              <Box component="fieldset" mb={3} borderColor="transparent">
                <Typography component="legend" >
                  <Rating
                    name={"customized-10" + i}
                    value={skill.value}
                    max={7}
                    mb={3}
                    size="small"
                    readOnly={true} />
                </Typography>
                { this.getCertifications(skill)}
              </Box>
            </Paper>
          </Cell>
        )
      })
    }
    else {
      return (
        <div> Skills</div>
      )
    }

  }
  render() {

    return (
      <Grid>
        <Cell col={12}>
          <Card style={{ minWidth: '100%', margin: 'auto' }}>
            <CardTitle style={{ textAlign: 'center' }} >
              {this.props.skill.domain}
            </CardTitle>
            <div style={{ display: 'flex' }}>
              <Grid col={12}>{this.getSkills()}</Grid>
            </div>
          </Card>
        </Cell>
      </Grid>
    )
  }






  handleClickOpen() {
    this.open = true;
  };

  handleClose() {
    this.open = false;
  };

  getDialogFrame() {
    console.log(" aha")
    return (
      <Dialog
        open={this.open}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
              </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose()} color="primary">
            Agree
              </Button>
        </DialogActions>
      </Dialog>
    );
  }



}

export default Skills;
