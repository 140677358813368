import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';

import Hobbies from './hobbies/hobbies';


const styles = {
  main:{
    minHeight: "82.4%",
    margin: 'auto',
    justifyContent: 'center'
  }
}

class About extends Component {

  constructor(props) {
    super(props)
    this.hobbie1 = {
      hobbies: [
        {
          title: "La Lecture",
          description: ["La lecture est un moment de jovial, de plaisir, mais aussi un moment de détente.",
            "Elle nous permet de découvrir des histoires, d'améliorer notre vocabulaire, d'apprendre des nouvelles expressions, mais également d'améliorer nos aptitudes à exercer mes pensées critiques.",
            "L'alchimiste de Paulo Coelho, Père riche Père Pauvre Robert Kiyosaki sont mes compagnons"],
          images: ['/images/lecture.jpg', '/images/alchimiste.jpg']
        },
        {
          title: "Les Jeux Vidéo",
          description: ["Tout d'abord, plusieurs études prouvent que les jeux vidéo nous permettent d'utiliser et de développer notre intelligence.",
            "En outre, j'aime réaliser des missions impossibles, de jouer avec une intelligence artificielle ainsi qu'avec des amis dans le but d'amuser et de m'améliorer.",
            "Il semblerait qu'en fait, les jeux vidéo font de vous un meilleur être humain."],
          images: ['/images/game.jpg', '/images/gta.jpg']
        },
        {
          title: "Le Football",
          description: ["Le Football nous plonge dans des moments agréables.",
            "Des moments où nous sommes en équipe afin de prouver l'importance du travail en équipe.",
            "Je supporte le Barcelone, mais c'est Neymar mon joueur."],
          images: ['/images/football.jpg', '/images/barcelone.png']
        },
        {
          title: "Jouer la Guitare",
          description: ["Haha, avec ma guitare, je suis toujours bien :-).",
            "La guitare est très onéreuse ; de plus, elle est légère.",
            "Je rêve de devenir un professionnel !"
          ],
          images: ['/images/guitare1.jpg', '/images/guitare.jpg']
        },
        {
          title: "Intelligence Artificielle",
          description: ["De nos jours, nous assistons à une révolution technologique qui a bouleversé à jamais le monde.",
            "Ceci se manifeste à des prises de décision de manière efficace et totalement indépendantes à l'interaction des humains.",
            "L'intelligence artificielle ne cesse d'aider les hommes à effectuer des taches qui n'ont jamais été simple à réaliser.",
            "Les mathématiques, la physique ainsi que l'informatique sont des domaines qui m'ont toujours plus."],
          images: ['/images/ia1.jpg', '/images/ia.jpg']
        },
        {
          title: "Découvrir l'univers",
          description: ["L'ensemble de ce qui existe, qui lie notre existence dont une grosse partie nous est inconnue.",
            "C'est l'univers. Est-ce qu'il est infini ?",
            "J'aime regarder des documentaires sur les théories de l'univers.",
            "De passage, je remercie Elon Musk et j'apprécie son projet SpaceX qui a beaucoup révolutionner ce domaine ! "],
          images: ['/images/univers.jpg', '/images/univers1.jpg']
        }
      ]
    }
  }

  hobbies() {
    return (
      <div className="col-12">
        <Hobbies hobbies={this.hobbie1} />
      </div>
    )
  }

  render() {
    return (
      <div style = {styles.main}>
        <div className="aboutme-body row align-items-center" >
          <br />
          <Grid className="aboutme-grid">
            <Cell col={4}>
              <Card shadow={5} style={{ minWidth: '450', margin: 'auto', height: '400px', textAlign: 'center', flex: 'flex' }}>
                <Paper className="aboutme-text">
                  <h3>Ibrahima SOUMARE</h3>
                  <img
                    src="/images/pp.png"
                    alt="avatar"
                    className="img-thumbnail"
                    style={{ height: '250px' }}
                  />
                  <br />
                  <p className="aboutme-text-content">
                    Consultant Data Engineer E.leclerc
                  </p>
                  <br />
                  <br />
                </Paper>
              </Card>
            </Cell>
            <Cell col={8}>
              <Card>
                <h2>A propos de moi</h2>
                <hr />
                <br /><br />
                <div >
                  <Paper className="aboutme-text">
                    <p >
                      Diplômé d'un master 2 en Informatique Décisionnelle à l'université, je suis Data Engineer / Scientist dans l'entreprise Homadata (expert de la donnée immobilière). 
                    </p>
                  </Paper>
                  <br />
                  <Paper className="aboutme-text">
                    <p  >
                      Je suis passionné par la collection de données, le traitement des données, le cloud ainsi que la programmation d'application.
                </p>
                  </Paper>
                  <br />
                  <Paper className="aboutme-text" >
                    <p >
                      La polyvalence, l'autonomie ainsi que l'enthousiasme sont mes compagnons indispensables.
                </p>
                  </Paper>
                </div>
              </Card>
            </Cell>
          </Grid>
        </div>
        <br /><br />
        {this.hobbies()}
        <br />
      </div>
    )
  }
}

export default About;
