import React, { Component } from 'react';
import './App.css';
import { Layout, Header, Navigation, Drawer, Content } from 'react-mdl';
import Main from './components/main';
import { Link } from 'react-router-dom';
import Foot from './components/footer';


class App extends Component {
  constructor(props) {
    super(props)
  }

  handlerClick(){
  }
  render() {

    return (
      <div >
        <Layout >
            <Header className="header-color " title={<Link style={{textDecoration: 'none', color: 'white'}} to="/" className="font-weight-bold">Accueil</Link>} scroll>
                <Navigation className= "font-weight-bold">
                  <Link to="/cv" className= "font-weight-bold">Curriculum Vitae</Link>
                  <Link to="/apropos" className= "font-weight-bold">A Propos</Link>
                  <Link to="/projets" className= "font-weight-bold">Projets</Link>
                  <Link to="/articles" className= "font-weight-bold">Articles</Link>
                  <Link to="/contactes" className= "font-weight-bold">Contact</Link>
                </Navigation>
            </Header>
            <Drawer className="" style={{backgroundColor: '#FFFFFF'}} title={<Link style={{textDecoration: 'none', color: 'black'}} to="/"  >Accueil</Link>}>
                <Navigation >
                  <Link to="/cv" className= "font-weight-bold text-black" >Curriculum Vitae</Link>
                  <Link to="/apropos" className= "font-weight-bold text-black">A Propos</Link>
                  <Link to="/projets" className= "font-weight-bold text-black">Projets</Link>
                  <Link to="/articles" className= "font-weight-bold text-black">Articles</Link>
                  <Link to="/contactes" className= "font-weight-bold text-black">Contact</Link>
                </Navigation>
            </Drawer>
            <Content className="">
                <div className="page-content" />
                <Main />
                <Foot/>
            </Content>
        </Layout>
    </div>

    );
  }
}

export default App;
